<template>

  <v-dialog
    v-model="isEducationHistoryDialogOpen"
    max-width="650px"
    @click:outside="onReset"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Ausbildung bearbeiten
      </v-card-title>
<!--      <v-card-text class="text-center mt-n2">
        Änderungen können nicht mehr rückgängig gemacht werden.
      </v-card-text>-->

      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="itemToEdit.dateFrom"
                outlined
                dense
                label="Von"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="itemToEdit.dateTo"
                outlined
                dense
                label="Bis"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                v-model="itemToEdit.schoolname"
                outlined
                dense
                label="Schule"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="itemToEdit.location.postcode"
                outlined
                dense
                label="PLZ"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="itemToEdit.location.city"
                outlined
                dense
                label="Ort"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
            >
              <v-select
                v-model="itemToEdit.location.country.code"
                label="Land"
                outlined
                dense
                :items="joinvisionDomains.ISOCountry"
                item-text="de"
                item-value="code"
                class="invoice-list-actions "
              ></v-select>
            </v-col>


          </v-row>

          <v-row>


            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="itemToEdit.graduation"
                outlined
                dense
                label="Abschluss"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
            >
              <v-select
                v-model="itemToEdit.educationType.code"
                label="Ausbildungstyp"
                outlined
                dense
                :items="joinvisionDomains.EducationType"
                item-text="de"
                item-value="code"
                class="invoice-list-actions "
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              sm="4"
            >
              <v-select
                v-model="itemToEdit.isced.code"
                label="ISCED"
                outlined
                dense
                :items="joinvisionDomains.ISCED"
                item-text="de"
                item-value="code"
                class="invoice-list-actions "
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <!--
                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-select
                            v-model="itemToEdit.operationArea.code"
                            label="Einsatzgebiet"
                            single-line
                            outlined
                            dense
                            :items="joinvisionDomains.OperationArea"
                            item-text="de"
                            item-value="code"
                            class="invoice-list-actions "
                          ></v-select>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-text-field
                            v-model="itemToEdit.operationArea.weight"
                            outlined
                            dense
                            label="Einsatzgebiet(Gewichtung)"
                          ></v-text-field>
                        </v-col>
            -->

            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                v-model="itemToEdit.comments"
                outlined
                dense
                label="Notizen"
              ></v-text-field>
            </v-col>


            <v-col
              cols="12"
              sm="12"
            >
              <v-textarea
                v-model="itemToEdit.plainText"
                outlined
                label="Beschreibung"
                rows="5"
                hide-details="auto"
                placeholder="Beschreibung"
              ></v-textarea>
            </v-col>


          </v-row>
          <v-row>

            <v-col
              cols="12"
              sm="12"
            >
              <div class="d-flex align-center justify-space-between mb-3">
                <span>Bestanden</span>
                <v-switch
                  v-model="itemToEdit.completed"
                  hide-details="auto"
                  class="mt-0"
                  :input-value="true"
                ></v-switch>
              </div>
            </v-col>

            <v-col
              cols="12"
              sm="12"
            >
              <div class="d-flex align-center justify-space-between mb-3">
                <span>Seitenumbruch</span>
                <v-switch
                  v-model="itemToEdit.pagebreak"
                  hide-details="auto"
                  class="mt-0"
                  :input-value="true"
                ></v-switch>
              </div>
            </v-col>

          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="onSubmit"
              >
                Speichern
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="onReset"
              >
                Abbrechen
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import {getCurrentInstance, ref, watch} from "@vue/composition-api";

export default {
  props: {
    action: {
      type: String,
      required: true,
      default: "edit",
    },
    isEducationHistoryDialogOpen: {
      type: Boolean,
      required: true,
    },
    clickedItem: {
      type: Object,
      required: true,
    }
  },
  setup(props, {emit}){

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    let joinvisionDomains = ref({
      CERF: [],
      CivilState: [],
      Contract: [],
      EducationType: [],
      Employment: [],
      Experience: [],
      Gender: [],
      ISCED: [],
      ISOCountry: [],
      ISOLanguage: [],
      NACE: [],
      OperationArea: [],
      Position: [],
      Skill: [],
    });

    const loadDomainCodes = (domain) => {
      joinvisionDomains.value[domain] = vm.$domainCodes[domain].filter(item => item.domain === domain);
    }

    const truncateDomainCodes = () => {
      joinvisionDomains.value = [];
    }

    const itemToEdit = ref({
      dateFrom: "",
      dateTo: "",
      duration: "",
      current: "",
      location: {
        country: {
          code: "",
          name: ""
        },
        postcode: "",
        city: "",
        state: "",
      },
      operationArea: {
        code: "",
        name: "",
        weight: ""
      },
      comments: "",
      plainText: "",
      isced: {
        code: "",
        name: ""
      },
      educationType: {
        code: "",
        name: ""
      },
      schoolname: "",
      graduation: "",
      completed: "",
      skill: [
        {
          code: "",
          name: "",
          weight: ""
        }
      ],
      pagebreak: false,
      uniqueId: "id" + Math.random().toString(16).slice(2)
    });

    watch(() => props.clickedItem, (newValue) => {
      if(props.isEducationHistoryDialogOpen){
        itemToEdit.value = JSON.parse(JSON.stringify(newValue))
      }
    });

    watch(() => props.isEducationHistoryDialogOpen, () => {
      if(props.isEducationHistoryDialogOpen){
        loadDomainCodes("ISOCountry")
        loadDomainCodes("OperationArea")
        loadDomainCodes("EducationType")
        loadDomainCodes("ISCED")
      }
      else{
        truncateDomainCodes()
      }
    });

    const onReset = () => {
      emit('update:is-education-history-dialog-open', false)
      itemToEdit.value = JSON.parse(JSON.stringify(props.clickedItem))
    }

    const onSubmit = () => {
      itemToEdit.value.changeSource = "onSubmit";
      emit('update:is-education-history-dialog-open', false)

      if(props.action == "edit"){
        emit('editEducationHistoryCheck', itemToEdit.value)
      }
      else{
        emit('createEducationHistoryCheck', itemToEdit.value)
      }
    }

    return {
      truncateDomainCodes,
      joinvisionDomains,
      loadDomainCodes,
      itemToEdit,
      onSubmit,
      onReset,
    }
  },
}
</script>

<style>
.v-textarea textarea {
  line-height: 1.2rem;
}
</style>
