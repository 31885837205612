<template>
  <div>
    <v-card class="mb-7">
      <v-card-text>



        <v-row>
          <v-col
            cols="11"
            sm="11"
          >
            <v-text-field
              v-model="freetextsCopy.accessibility"
              outlined
              dense
              hide-details
              label="Verfügbarkeit"
            ></v-text-field>
          </v-col>
          <v-col
            cols="1"
            sm="1"
          >
            <v-icon size="40" @click="editFreetextsCheck()" title="Speichern" color="primary">
              {{ icons.mdiContentSaveSettingsOutline   }}
            </v-icon>

          </v-col>

          <v-col
            cols="11"
            sm="11"
          >
            <v-text-field
              v-model="freetextsCopy.conditions"
              outlined
              dense
              hide-details
              label="Konditionen"
            ></v-text-field>
          </v-col>
          <v-col
            cols="1"
            sm="1"
          >
            <v-icon size="40" @click="editFreetextsCheck()" title="Speichern" color="primary">
              {{ icons.mdiContentSaveSettingsOutline   }}
            </v-icon>
          </v-col>

          <v-col
            cols="9"
            sm="9"
          >
            <v-text-field
              v-model="personalInformationCopy.address.city"
              outlined
              dense
              hide-details
              label="Ort"
            ></v-text-field>
          </v-col>

          <v-col
            cols="2"
            sm="2"
          >
            <v-text-field
              v-model="reichweiteInKmCopy"
              outlined
              dense
              label="Reichweite (km)"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col
            cols="1"
            sm="1"
          >
            <v-icon size="40" @click="editCityAndRangeCheck()" title="Speichern" color="primary">
              {{ icons.mdiContentSaveSettingsOutline   }}
            </v-icon>
          </v-col>


          <v-col
            cols="11"
            sm="11"
          >
            <v-text-field
              v-model="personalInformationCopy.currentJob"
              outlined
              dense
              label="Aktueller Beruf"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="1"
            sm="1"
          >
            <v-icon size="40" @click="editPersonalInformationCheck()" title="Speichern" color="primary">
              {{ icons.mdiContentSaveSettingsOutline   }}
            </v-icon>
          </v-col>
          <v-col
            cols="11"
            sm="11"
          >
            <v-select
              v-model="idThJobDefinitionCopy"
              outlined
              dense
              label="Job-Definition"
              hide-details
              :items="jobDefinitions"
              item-text="displayName"
              item-value="idThJobDefinition"
              class="invoice-list-actions "
            ></v-select>
          </v-col>
          <v-col
            cols="1"
            sm="1"
          >
            <v-icon size="40" @click="editJobDefinitionCheck()" title="Speichern" color="primary">
              {{ icons.mdiContentSaveSettingsOutline   }}
            </v-icon>
          </v-col>

          <v-col
            cols="12"
            sm="12"
          >
            <div class="mb-1">
              <v-icon size="18" v-if="editMode.interestingFacts" @click="editFreetextsCheck('interestingFacts')" title="Speichern" color="primary">
                {{ icons.mdiContentSaveSettingsOutline   }}
              </v-icon>
              <v-icon size="18" v-else @click="editMode.interestingFacts = true" title="Editieren" color="primary">
                {{ icons.mdiPencilOutline }}
              </v-icon>
              Wissenswertes
              <br>
            </div>
            <template v-if="editMode.interestingFacts">
              <v-textarea
                v-model="freetextsCopy.interestingFacts"
                outlined
                rows="15"
                hide-details
              ></v-textarea>
            </template>
            <template v-else>
              <span v-html="getTextOfAsteriskText(freetextsCopy.interestingFacts)"></span>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPencilOutline,
  mdiContentSaveSettingsOutline  ,
} from '@mdi/js'
import {getCurrentInstance, ref, watch} from '@vue/composition-api'


export default {
  components:{

  },
  props: {
    freetexts: {
      type: Object,
      required: true,
    },
    personalInformation: {
      type: Object,
      required: true,
    },
    idThJobDefinition: {
      type: String,
      required: false,
    },
    jobDefinitions: {
      type: Array,
      required: false,
    },
    reichweiteInKm: {
      type: String,
      required: false,
    }
  },
  setup(props,{emit}) {
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    const freetextsCopy = ref({});
    freetextsCopy.value = JSON.parse(JSON.stringify(props.freetexts))
    watch(() => props.freetexts, (newValue) => {
      freetextsCopy.value = JSON.parse(JSON.stringify(props.freetexts))
    });

    const personalInformationCopy = ref({});
    personalInformationCopy.value = JSON.parse(JSON.stringify(props.personalInformation))
    watch(() => props.personalInformation, (newValue) => {
      personalInformationCopy.value = JSON.parse(JSON.stringify(props.personalInformation))
    });

    const reichweiteInKmCopy = ref("30");
    reichweiteInKmCopy.value = JSON.parse(JSON.stringify(props.reichweiteInKm))
    watch(() => props.reichweiteInKm, (newValue) => {
      reichweiteInKmCopy.value = JSON.parse(JSON.stringify(props.reichweiteInKm))
    });

    const idThJobDefinitionCopy = ref("");
    idThJobDefinitionCopy.value = JSON.parse(JSON.stringify(props.idThJobDefinition));
    watch(() => props.idThJobDefinition, (newValue) => {
      idThJobDefinitionCopy.value = JSON.parse(JSON.stringify(newValue))
    });

    const editPersonalInformationCheck = (editField) => {
      emit('editPersonalInformationCheck',personalInformationCopy.value)
    }

    const editFreetextsCheck = (editField) => {
      if(editField){
        editMode.value[editField] = false;
      }
      emit('editFreetextsCheck',freetextsCopy.value)
    }

    const editJobDefinitionCheck = (editField) => {
      emit('editJobDefinitionCheck',idThJobDefinitionCopy.value)
    }

    const editCityAndRangeCheck = () => {
      emit('editCityAndRangeCheck',personalInformationCopy.value.address.city, reichweiteInKmCopy.value)
    }

    const editMode = ref({
      interestingFacts : false
    });

    const getTextOfJobDefinition = () => {
      let text = "";
      props.jobDefinitions.forEach(item => {
        if(item.idThJobDefinition == idThJobDefinitionCopy.value){
          text = item.displayName;
        }
      })
      return text;
    }

    const getTextOfAsteriskText = (text) => {
      text = text.replace("*","####");
      text = text.replaceAll("*","<br>*");
      text = text.replaceAll("####","*");
      return text;
    }

    return {
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPencilOutline,
        mdiContentSaveSettingsOutline  ,
      },
      getTextOfJobDefinition,
      editFreetextsCheck,
      freetextsCopy,
      personalInformationCopy,
      idThJobDefinitionCopy,
      editPersonalInformationCheck,
      editFreetextsCheck,
      editJobDefinitionCheck,
      reichweiteInKmCopy,
      editCityAndRangeCheck,
      editMode,
      getTextOfAsteriskText,
    }
  },
}
</script>

<style scoped>
.v-align-top{
  vertical-align: top;
}
.v-align-middle{
  vertical-align: middle;
}
.table-compact td:first-child{
  width:170px;
  /*height:40px;*/
}
</style>
