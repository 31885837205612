<template>
  <v-dialog
    v-model="isDialogOpen"
    max-width="650px"
    @click:outside="onReset"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        <template v-if="title">
          {{title}}
        </template>
        <template v-else>
          Freitext bearbeiten
        </template>
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Änderungen können nicht mehr rückgängig gemacht werden.
      </v-card-text>
      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">
            <v-row >
              <v-col
                cols="12"
                sm="12"
              >
                <v-textarea
                  v-model="itemCopy"
                  outlined
                  :label="label"
                  rows="10"
                  hide-details="auto"
                  :placeholder="label"
                ></v-textarea>
              </v-col>
            </v-row>
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"

                type="submit"
                @click.prevent="onSubmit"
              >
                Speichern
              </v-btn>
              <v-btn
                outlined
                color="secondary"
                @click.prevent="onReset"
              >
                Abbrechen
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {getCurrentInstance, ref, watch} from "@vue/composition-api";

export default {
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default:"Freitexte",
    },
    label: {
      type: String,
      default:"Freitext",
    },
    item: {
      type: String,
      default:"",
      required: true,
    }
  },
  setup(props, { emit }) {

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    const itemCopy = ref("");
    itemCopy.value = JSON.parse(JSON.stringify(props.item))

    watch(() => props.item, (newValue,oldValue) => {
      if(props.isDialogOpen){
        itemCopy.value =  JSON.parse(JSON.stringify(newValue))
      }
    });

    const onReset = () => {
      emit('update:is-dialog-open',false)
      itemCopy.value = JSON.parse(JSON.stringify(props.item))
    }

    const onSubmit = () => {
      emit('update:is-dialog-open',false)
      emit('save',itemCopy.value)
    }

    return {
      itemCopy,
      onSubmit,
      onReset,
    }
  },
}
</script>

<style>
.v-textarea textarea {
  line-height: 1.2rem;
}
</style>
