<template>
  <v-card class="user-tab-notification">
<!--    <v-card-title>
      Diagramme
    </v-card-title>
    <v-divider></v-divider>-->
    <v-card-text class="text--primary font-weight-medium">

      <template v-for="(image,imageIndex) in additionalImages" >
        <div class="col m-3">
          <div class="d-flex flex-column align-items-center" v-if="image !== null" >
            <strong>{{image.split(/[\\/]/).pop()}}</strong>
            <div>
              <a :href="image" target="_blank">  <img class="img-thumbnail" style="max-width:300px;" :src="image" /></a>
            </div>
            <hr>
          </div>
        </div>
      </template>
    </v-card-text>


  </v-card>
</template>

<script>
export default {
  props:{
    additionalImages:{
      type:Array,
      required:true
    }
  }
}
</script>
