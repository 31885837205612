import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"650px"},on:{"click:outside":_vm.onReset},model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[_c(VCard,{staticClass:"user-edit-info pa-sm-10 pa-3"},[_c(VCardTitle,{staticClass:"justify-center text-h5"},[(_vm.title)?[_vm._v(" "+_vm._s(_vm.title)+" ")]:[_vm._v(" Freitext bearbeiten ")]],2),_c(VCardText,{staticClass:"text-center mt-n2"},[_vm._v(" Änderungen können nicht mehr rückgängig gemacht werden. ")]),_c(VCardText,{staticClass:"mt-5"},[_c(VForm,{staticClass:"multi-col-validation"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12"}},[_c(VTextarea,{attrs:{"outlined":"","label":_vm.label,"rows":"10","hide-details":"auto","placeholder":_vm.label},model:{value:(_vm.itemCopy),callback:function ($$v) {_vm.itemCopy=$$v},expression:"itemCopy"}})],1)],1),_c(VRow,[_c(VCol,{staticClass:"d-flex justify-center mt-3",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"me-3",attrs:{"color":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v(" Speichern ")]),_c(VBtn,{attrs:{"outlined":"","color":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.onReset.apply(null, arguments)}}},[_vm._v(" Abbrechen ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }