<template>
  <v-row class="user-bio-panel">
    <v-col cols="12">
      <v-card class="">
        <v-card-text class=" justify-center flex-column mb-0 pb-0">
          <div class="plan-banner d-flex align-center flex-wrap pa-3 mb-7">
            <div class="font-weight-medium ms-3">
              <h2 class="text--primary mb-0">
                {{ personalInformation.firstname }} {{personalInformation.lastname}}
              </h2>
              <span class="text-xs primary--text">{{ personalInformation.currentJob }} </span>
            </div>
          </div>
          <v-select
            v-model="stateCopy"
            label="Status"
            outlined
            dense
            :items="stateOptions"
            item-text="key"
            item-value="value"
            @change="$emit('editState',stateCopy)"
          ></v-select>
          <v-select
            v-model="idThJobDefinitionCopy"
            label="Job-Definition"
            outlined
            dense
            :items="jobDefinitions"
            item-text="displayName"
            item-value="idThJobDefinition"
            @change="editJobDefinitionCheck"
            class="invoice-list-actions "
          ></v-select>
          <v-row>
            <v-col cols="6">
              <v-avatar
                :color="avatar ? '' : 'primary'"
                :class="avatar ? '' : 'v-avatar-light-bg primary--text'"
                size="190"
                rounded
                class="mb-4"
                @click="$refs.file.click()"
                style="cursor: pointer"
              >
                <v-img
                  class="uploadImage"
                  v-if="photo !== null"
                  :src="'data:image/'+photo.filetype+';base64, '+photo.binary"

                ></v-img>
                <span
                  v-else
                  class="font-weight-semibold text-5xl"
                >{{avatarText(personalInformation.firstname + personalInformation.lastname)}}</span>
              </v-avatar>
              <input ref="file" v-on:change="onFileSelected" type="file" id="candidatePhoto">
            </v-col>
            <v-col cols="6" class="text-left">
              <div class="flex-column justify-center align-center ">
                <v-btn type="button" class="mb-3 full-width text-left justify-start" @click="onEditClick" color="primary" outlined>
                  <v-icon size="18">
                    {{ icons.mdiPencilOutline }}
                  </v-icon>&nbsp;Details
                </v-btn>
                <v-btn type="button" class="mb-3 full-width text-left justify-start" @click="onEditFreetextsClick" color="primary" outlined>
                  <v-icon size="18">
                    {{ icons.mdiPencilOutline }}
                  </v-icon>&nbsp;Freitexte
                </v-btn>

                <v-btn type="button" class="mb-3 full-width text-left justify-start" @click="onEditFilesClick" color="primary" outlined>
                  <v-icon size="18">
                    {{ icons.mdiCloudDownloadOutline }}
                  </v-icon>&nbsp;Dateien
                </v-btn>
                <v-btn class="mb-3 justify-start full-width" color="primary"  outlined>
                  <a class="pdfPreviewLink"
                     v-if="router.currentRoute.params.idThBewerber !== undefined"
                     :href="'/api/bewerber/pdf/'+router.currentRoute.params.idThBewerber+'/'"
                     target="_blank">
                    <v-icon size="18">
                      {{ icons.mdiFilePdf }}
                    </v-icon>&nbsp;PDF
                  </a>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="text-center">
          <v-row>
            <v-col cols="3">
              <div>
                <v-icon
                  size="30"
                  :color="stateDataCopy.angabeDsgvo ? 'success' : ''"
                  @click="toggleState('angabeDsgvo')"
                >
                  {{ icons.mdiCheckboxMarkedCircleOutline  }}
                </v-icon>
              </div>
              <span class="text-no-wrap " :class="stateDataCopy.angabeDsgvo ? 'success--text' : 'text--success'">
                DSGVO
              </span>
            </v-col>
            <v-col cols="3">
              <div>
                <v-icon
                  size="30"
                  :color="stateDataCopy.angabeSuchparameter ? 'success' : ''"
                  @click="toggleState('angabeSuchparameter')"
                >
                  {{ icons.mdiAccountSearchOutline  }}
                </v-icon>
              </div>
              <span class="text-no-wrap " :class="stateDataCopy.angabeSuchparameter ? 'success--text' : 'text--success'">
                Suchparam.
              </span>
            </v-col>
            <v-col cols="3">
              <div>
                <v-icon
                  size="30"
                  :color="stateDataCopy.angabeUnterlagen ? 'success' : ''"
                  @click="toggleState('angabeUnterlagen')"
                >
                  {{ icons.mdiTextBoxMultipleOutline }}
                </v-icon>
              </div>
              <span class="text-no-wrap " :class="stateDataCopy.angabeUnterlagen ? 'success--text' : 'text--success'">
                Unterlagen
              </span>
            </v-col>
            <v-col cols="3">
              <div>
                <v-icon
                  size="30"
                  :color="stateDataCopy.angabeOeffentlich ? 'success' : ''"
                  @click="toggleState('angabeOeffentlich')"
                >
                  {{ icons.mdiEarth }}
                </v-icon>
              </div>
              <span class="text-no-wrap " :class="stateDataCopy.angabeOeffentlich ? 'success--text' : 'text--success'">
                Öffentlich
              </span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-divider></v-divider>
          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Name:</span>
              <span class="text--secondary">{{
                  personalInformation.title
                }} {{ personalInformation.firstname }} {{ personalInformation.lastname }}</span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Straße:</span>
              <span class="text--secondary">{{ personalInformation.address.street }}</span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">PLZ:</span>
              <span class="text--secondary">{{
                  personalInformation.address.postcode
                }} {{ personalInformation.address.city }}</span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Land:</span>
              <span class="text--secondary">{{
                  getTextOfCode('ISOCountry', personalInformation.address.country.code)
                }} ({{ personalInformation.address.state }})</span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Geburtdatum:</span>
              <span class="text--secondary">{{ personalInformation.birthdate }}</span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">ISCED:</span>
              <span class="text--secondary">{{
                  getTextOfCode('ISCED', personalInformation.isced.code)
                }}</span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Nationalität:</span>
              <span class="text--secondary">{{
                  getTextOfCode('ISOCountry', personalInformation.nationality.code)
                }}</span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">E-Mail:</span>
              <span class="text--secondary">{{ personalInformation.email }}</span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Telefon:</span>
              <span class="text--secondary">{{ personalInformation.phoneNumber }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <router-link :to="'/bewerber'">
            <v-btn
              color="error"
            >
              Zurück
            </v-btn>
          </router-link>
        </v-card-actions>
      </v-card>

      <user-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :personal-information="personalInformation"
        :idThJobDefinition="idThJobDefinitionCopy"
        :jobDefinitions="jobDefinitions"
        @editPersonalInformationCheck="editPersonalInformationCheck"
      ></user-bio-edit>

      <user-bio-freetexts
        :is-dialog-open.sync="isFreetextsDialogOpen"
        :freetexts="freetexts"
        @editFreetextsCheck="editFreetextsCheck"
      ></user-bio-freetexts>

      <dialog-edit-files
        :is-dialog-open.sync="isFilesDialogOpen"
        :files.sync="filesCopy"
        @save="editFilesCheck"
      ></dialog-edit-files>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiCheck,
  mdiBriefcaseVariantOutline,
  mdiCheckboxBlankCircle,
  mdiFilePdf,
  mdiCloudUpload,
  mdiPencilOutline,
  mdiCloudDownloadOutline,
  mdiCheckboxMarkedCircleOutline ,
  mdiAccountSearchOutline ,
  mdiTextBoxMultipleOutline,
  mdiEarth,
} from '@mdi/js'
import {avatarText, kFormatter} from '@core/utils/filter'
import {getCurrentInstance, ref, watch} from '@vue/composition-api'
import UserBioEdit from './UserBioEdit.vue'
import UserBioFreetexts from './UserBioFreetexts.vue'
import router from '@/router'
import DialogEditFiles from "@/views/candidate/user-view/DialogEditFiles";

export default {
  components: {
    DialogEditFiles,
    UserBioEdit,
    UserBioFreetexts,
    router
  },
  props: {
    personalInformation: {
      type: Object,
      required: true,
    },
    freetexts: {
      type: Object,
      required: true,
    },
    files: {
      type: [],
      required: false,
    },
    photo: {
      type: Object,
      required: true,
    },
    headhunter: {
      type: String,
      required: true,
    },
    avatar: {
      type: String,
      required: false,
    },
    projectDone: {
      type: Boolean,
      required: false,
    },
    state: {
      type: String,
      required: false,
    },
    stateData: {
      type: Object,
      required: false,
    },
    idThJobDefinition: {
      type: String,
      required: false,
    },
    jobDefinitions: {
      type: Array,
      required: false,
    },
  },
  setup(props, {emit}){

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    const isBioDialogOpen = ref(false)
    const onEditClick = () => {
      isBioDialogOpen.value = !isBioDialogOpen.value;
    }

    const isFreetextsDialogOpen = ref(false)
    const onEditFreetextsClick = () => {
      isFreetextsDialogOpen.value = !isFreetextsDialogOpen.value;
    }
    const isFilesDialogOpen = ref(false)
    const onEditFilesClick = () => {
      isFilesDialogOpen.value = !isFilesDialogOpen.value;
    }

    const editPersonalInformationCheck = (personalInformation) => {
      emit("editPersonalInformationCheck", personalInformation);
    }

    const editJobDefinitionCheck = () => {
      emit("editJobDefinitionCheck", idThJobDefinitionCopy.value);
    }

    const editFreetextsCheck = (newItem) => {
      emit("editFreetextsCheck", newItem);
    }

    const editFilesCheck = (selectedFiles, deletedFiles, changedFiles) => {
      emit("editFilesCheck", selectedFiles, deletedFiles, changedFiles);
    }

    const getTextOfCode = (domain, code) => {
      let joinvisionDomains = vm.$domainCodes;
      if(joinvisionDomains[domain] !== undefined){
        let array = joinvisionDomains[domain].filter(item => item.code === code);
        if(array[0] === undefined) return "";
        return array[0].de;
      }
    }

    const onFileSelected = (event) => {
      emit("editPhotoCheck", event.target.files[0]);
    }

    const stateCopy = ref("");
    stateCopy.value = JSON.parse(JSON.stringify(props.state));
    watch(() => props.state, (newValue) => {
      stateCopy.value = JSON.parse(JSON.stringify(newValue))
    });

    const stateDataCopy = ref("");
    stateDataCopy.value = JSON.parse(JSON.stringify(props.stateData));
    watch(() => props.stateData, (newValue) => {
      stateDataCopy.value = JSON.parse(JSON.stringify(newValue))
    });

    const idThJobDefinitionCopy = ref("");
    idThJobDefinitionCopy.value = JSON.parse(JSON.stringify(props.idThJobDefinition));
    watch(() => props.idThJobDefinition, (newValue) => {
      idThJobDefinitionCopy.value = JSON.parse(JSON.stringify(newValue))
    });

    const filesCopy = ref([]);
    filesCopy.value = JSON.parse(JSON.stringify(props.files));
    watch(() => props.files, (newValue) => {
      filesCopy.value = JSON.parse(JSON.stringify(newValue))
    });

    const toggleState = (stateKey) => {
      stateDataCopy.value[stateKey] = !stateDataCopy.value[stateKey];
      emit("editStateData",stateDataCopy.value);
    }

    const stateOptions = [
      {value: "beworben", key: "Beworben"},
      {value: "lebenslaufErhalten", key: "Lebenslauf erhalten"},
      {value: "interviewGemacht", key: "Interview gemacht"},
      {value: "imSale", key: "Im Sale"},
      {value: "vorstellungsgespraech", key: "Vorstellungsgespräch"},
      {value: "vermittelt", key: "Vermittelt"},
      {value: "archiviertQualifiziert", key: "Archiviert Qualifiziert"},
      {value: "archiviertNichtQualifiziert", key: "Archiviert Nicht Qualifiziert"}
    ]

    return {
      onEditClick,
      editPersonalInformationCheck,
      editJobDefinitionCheck,
      editFreetextsCheck,
      editFilesCheck,
      onEditFreetextsClick,
      onEditFilesClick,
      onFileSelected,
      avatarText,
      kFormatter,
      isBioDialogOpen,
      isFreetextsDialogOpen,
      isFilesDialogOpen,
      getTextOfCode,
      stateOptions,
      stateCopy,
      stateDataCopy,
      filesCopy,
      idThJobDefinitionCopy,
      toggleState,
      router,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiPencilOutline,
        mdiCloudDownloadOutline,
        mdiFilePdf,
        mdiCloudUpload,
        mdiCheckboxMarkedCircleOutline,
        mdiAccountSearchOutline,
        mdiTextBoxMultipleOutline,
        mdiEarth,
      },
    }
  },
}
</script>

<style>
#candidatePhoto {
  display: none
}

.uploadImage {
  cursor: pointer;
}

.pdfPreviewLink,
.pdfPreviewLink:link,
.pdfPreviewLink:visited,
.pdfPreviewLink:hover,
.pdfPreviewLink:active {
  text-decoration: none;
  color: unset;
}

.v-list-item {
  min-height: 30px;
}

.full-width{
  width:100%;
}
.plan-banner{
background: rgba(145, 85, 253, 0.12)
}
</style>
