<template>
  <v-dialog
    v-model="isDialogOpen"
    max-width="650px"
    @click:outside="onReset"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Dateien bearbeiten
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Änderungen können nicht mehr rückgängig gemacht werden.
      </v-card-text>
      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">
          <v-row>
            <v-col
              cols="12"
              sm="12"
              v-cloak @drop.prevent="addDropFile" @dragover.prevent
            >
              <template>
                <v-file-input
                  :accept="accept.join(',')"
                  v-model="selectedFiles"
                  chips
                  multiple
                  counter
                  label="Dateien auswählen"
                ></v-file-input>
              </template>
            </v-col>
          </v-row>
          <v-row v-for="(listItem,listItemIndex) in filesCopy" :key="listItemIndex">
            <v-col
              cols="12"
              class="d-flex justify-center  mt-3"
            >
              <v-text-field
                v-model="listItem.anzeigename"
                outlined
                dense
                label="Anzeigename"
                class="mr-5 ml-5"
              ></v-text-field>
              <div class="d-flex align-center justify-center">
                <button type="button" class="v-btn v-btn--icon v-btn--round theme--light v-size--small">
                  <a :href="'/api/bewerber/datei/'+listItem.idThBewerber+'/'+listItem.idThBewerberDatei+'/'" download>
                    <v-icon
                      size="18"
                    >
                      {{ icons.mdiCloudDownloadOutline }}
                    </v-icon>
                  </a>
                </button>
                <button type="button" class="v-btn v-btn--icon v-btn--round theme--light v-size--small">
                  <v-icon
                    size="18"
                    @click="onDeleteClick(listItem,listItemIndex)"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </button>
              </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="filesCopy.length >= 2">
            <v-col
              cols="12"
              class="d-flex justify-center  mt-3"
            >
              <v-btn
                color="second"
                class=""
              >
                <a :href="'/api/bewerber/zip/'+filesCopy[0].idThBewerber+'/'" download>Download Zip</a>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"

                type="submit"
                @click.prevent="onSubmit"
              >
                Speichern
              </v-btn>
              <v-btn
                outlined
                color="secondary"
                @click.prevent="onReset"
              >
                Abbrechen
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import {getCurrentInstance, ref, watch} from "@vue/composition-api";

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiCloudDownloadOutline,
} from '@mdi/js'

export default {

  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
    files: {
      type: Array,
      default(){
        return []
      },
      required: true,
    }
  },
  setup(props, {emit}){

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    const filesCopy = ref([]);
    const selectedFiles = ref([]);
    const deletedFiles = ref([]);

    watch(() => props.files, (newValue, oldValue) => {
      filesCopy.value = Object.values(newValue);
    });

    const accept = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "application/pdf",
    ];

    const onDeleteClick = (listItem, listItemIndex) => {
      filesCopy.value = filesCopy.value.filter((item, key) => key != listItemIndex);
      deletedFiles.value.push(listItem);
    }

    const onReset = () => {
      emit('update:is-dialog-open', false)
      filesCopy.value = Object.values(props.files);
      selectedFiles.value = [];
      deletedFiles.value = [];
    }

    const onSubmit = () => {
      emit('save', selectedFiles.value, deletedFiles.value, filesCopy.value);
      selectedFiles.value = [];
      deletedFiles.value = [];
    }

    const addDropFile = (e) => {
      selectedFiles.value.push(...Array.from(e.dataTransfer.files))
    }

    return {
      accept,
      filesCopy,
      addDropFile,
      selectedFiles,
      deletedFiles,
      onDeleteClick,
      onSubmit,
      onReset,
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiCloudDownloadOutline
      },
    }
  },
}
</script>

<style>
.v-textarea textarea {
  line-height: 1.2rem;
}
</style>
