<template>
  <div class="user-tab-education-history">
    <v-card id="invoice-list">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center pb-5">
          <v-select
            v-model="selectedMultiAction"
            label="Aktionen"
            outlined
            dense
            :items="actions"
            hide-details
            :disabled="Boolean(!selectedTableData.length)"
            @change="callMultiAction"
            class="invoice-list-actions me-3"
          ></v-select>
          <v-btn
            color="primary"
            class="me-3"
            @click="onCreateClick"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Ausbildung</span>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center pb-5">
          <v-text-field
            v-model="searchQuery"
            dense
            outlined
            hide-details
            placeholder="Suchen"
            class="invoice-list-search me-3"
          ></v-text-field>
        </div>
      </v-card-text>
      <v-data-table
        v-model="selectedTableData"
        :headers="tableColumns"
        :items="invoiceListTable"
        @current-items="refreshTable"
        item-key="key"
        :options.sync="options"
        :server-items-length="totalInvoiceListTable"
        :loading="loading"
        show-select
        class="text-no-wrap"
        :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50]}"
      >
        <template v-slot:body="{items, isSelected, select}">
          <draggable
            :list="items"
            tag="tbody"
            @end="sortEducationHistoryCheck(items)"
          >
            <tr
              v-for="(item, index) in items"
              :key="index"
            >
              <td>
                <v-checkbox
                  :input-value="isSelected(item)"
                  style="margin:0px;padding:0px"
                  color="#535353"
                  hide-details
                  @click="select(item,!isSelected(item))"
                >
                </v-checkbox>
              </td>
              <td>
                <v-icon
                  small
                  class="page__grab-icon"
                  style="cursor:pointer"
                  title="Verschieben"
                >
                  {{ icons.mdiArrowAll }}

                </v-icon>
              </td>
              <td>{{item.dateFrom}}</td>
              <td>{{item.dateTo}}</td>
              <td>{{item.schoolname}}</td>
              <td>{{item.graduation}}</td>
              <td>

                <div class="d-flex align-center justify-center">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        @click="onEditClick($event,item.key,on,attrs)"
                        v-on="on"
                      >
                        <v-icon size="18">
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Editieren</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        @click="openDialogDeleteEducationHistory( $event,item.key )"
                        v-on="on"
                      >
                        <v-icon size="18">
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Löschen</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        @click="openDialogEditSkills($event,item.key,on,attrs)"
                        v-on="on"
                      >
                        <v-icon size="18">
                          {{ icons.mdiAlphaFBoxOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Fähigkeiten</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        @click="openDialogEditFreetexts($event,item.key,on,attrs)"
                        v-on="on"
                      >
                        <v-icon size="18">
                          {{ icons.mdiAlphaTBoxOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Freitexte</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        @click="openDialogMoveEducationHistory( $event,item.key )"
                        v-on="on"
                      >
                        <v-icon size="18">
                          {{ icons.mdiSwapHorizontalBold }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>In den Werdegang verschieben</span>
                  </v-tooltip>

                </div>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-card>

    <dialog-edit-education-history
      :action="action"
      :is-education-history-dialog-open.sync="isDialogEditEducationHistoryOpen"
      :clicked-item.sync="clickedItem"
      @createEducationHistoryCheck="createEducationHistoryCheck"
      @editEducationHistoryCheck="editEducationHistoryCheck"
    ></dialog-edit-education-history>

    <dialog-edit-code-list
      domain="Skill"
      title="Fähigkeiten"
      label="Fähigkeit"
      :is-dialog-open.sync="isDialogEditSkillsOpen"
      :list.sync="skillsOfClickedItem"
      @save="editEducationHistorySkillCheck"
    ></dialog-edit-code-list>

    <dialog-edit-freetexts
      :is-dialog-open.sync="isDialogEditFreetextsOpen"
      :list.sync="freetextsOfClickedItem"
      @save="editEducationHistoryFreetextsCheck"
    ></dialog-edit-freetexts>

    <v-dialog
      v-model="isDialogDeleteEducationHistoryOpen"
      width="500px"
    >
      <v-card>
        <v-card-text
          class="text-center"
        >
          Möchten Sie diese Position(en) wirklich löschen?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="deleteEducationHistoryCheck"
          >
            Löschen
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="closeDialogDeleteEducationHistory"
          >
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isDialogMoveEducationHistoryOpen"
      width="500px"
    >
      <v-card>
        <v-card-text
          class="text-center"
        >
          Möchten Sie diese Position(en) wirklich in den Werdegang verschieben?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="moveEducationHistoryCheck"
          >
            Verschieben
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="closeDialogMoveEducationHistory"
          >
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script setup>
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiAlphaFBoxOutline,
  mdiAlphaTBoxOutline,
  mdiArrowAll,
  mdiSwapHorizontalBold,
} from '@mdi/js'

import {ref, watch, nextTick} from '@vue/composition-api'
import DialogEditEducationHistory from './DialogEditEducationHistory.vue'
import DialogEditCodeList from '@/views/candidate/user-view/DialogEditCodeList.vue'
import DialogEditFreetexts from '@/views/candidate/user-view/DialogEditFreetexts.vue'
import Draggable from 'vuedraggable';

export default {
  components: {
    DialogEditEducationHistory,
    DialogEditCodeList,
    DialogEditFreetexts,
    Draggable
  },
  props: {
    educationHistory: {
      type: Array,
      required: true,
    },
    reloadTable: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, {emit}){

    const action = ref("edit");
    const clickedKey = ref({});
    const clickedItem = ref({});
    const freetextsOfClickedItem = ref([]);
    const skillsOfClickedItem = ref([]);

    let isDialogEditEducationHistoryOpen = ref(false)
    let isDialogDeleteEducationHistoryOpen = ref(false)
    let isDialogMoveEducationHistoryOpen = ref(false)
    let isDialogEditSkillsOpen = ref(false)
    let isDialogEditFreetextsOpen = ref(false)

    const refreshTable = (e) => {
      console.log("refreshTable", e);
    }

    const selectedMultiAction = ref('')
    const selectedTableData = ref([])
    const invoiceListTable = ref([]);
    const totalInvoiceListTable = ref(0)
    const loading = ref(false)
    const searchQuery = ref('')
    const options = ref({
      itemsPerPage: 50,
      sortBy: ['id'],
      sortDesc: [true],
    })

    const actions = ['Löschen','Verschieben'];
    const tableColumns = [
      {text: '', value: '', sortable: false},
      {text: 'Von', value: 'dateFrom', sortable: false},
      {text: 'Bis', value: 'dateTo', sortable: false},
      {text: 'Schule', value: 'schoolname', sortable: false, width: '100px'},
      {text: 'Abschluss', value: 'graduation', sortable: false, width: '50px'},
      {text: 'Aktionen', value: 'actions', sortable: false, align: 'center'},
    ];

    const reloadTable = (newItem, oldItem) => {
      loading.value = true
      invoiceListTable.value = [];
      nextTick(() => {
        invoiceListTable.value = props.educationHistory;
        if(newItem !== undefined && newItem[0] !== undefined && newItem[0] != ''){
          let searchString = '';
          searchString = newItem[0];
          invoiceListTable.value = invoiceListTable.value.filter((item) => {
            return (item.graduation.toString().toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
              || (item.schoolname.toString().toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
              || (item.dateFrom.toString().toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
              || (item.dateTo.toString().toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
          })
        }
        if(options.value.page !== undefined && options.value.itemsPerPage){
          let limit = {};
          limit.from = (options.value.page - 1) * (options.value.itemsPerPage)
          limit.to = limit.from + options.value.itemsPerPage - 1;
          invoiceListTable.value = invoiceListTable.value.slice(limit.from, (limit.to) + 1);
        }
        totalInvoiceListTable.value = props.educationHistory.length
        loading.value = false
        emit("tableReloaded")
      })
    };
    watch([searchQuery, options], reloadTable)

    watch(() => props.educationHistory, () => {
      reloadTable();
    });

    watch(() => props.reloadTable, () => {
      if(props.reloadTable){
        reloadTable();
      }
    });

    const onCreateClick = () => {
      clickedItem.value = {
        dateFrom: "",
        dateTo: "",
        duration: "",
        current: "",
        location: {
          country: {
            code: "",
            name: ""
          },
          postcode:"",
          city:"",
          state:"",
        },
        operationArea: {
          code: "",
          name: "",
          weight: ""
        },
        comments: "",
        plainText: "",
        isced: {
          code: "",
          name: ""
        },
        educationType: {
          code: "",
          name: ""
        },
        schoolname: "",
        graduation: "",
        completed: "",
        skill: [
          {
            code: "",
            name: "",
            weight: ""
          }
        ],
        uniqueId: "id" + Math.random().toString(16).slice(2)
      };
      action.value = "create";
      isDialogEditEducationHistoryOpen.value = !isDialogEditEducationHistoryOpen.value;
    }

    const onEditClick = (event, key) => {
      action.value = "edit";
      clickedKey.value = key;
      clickedItem.value = JSON.parse(JSON.stringify(props.educationHistory[key]))
      isDialogEditEducationHistoryOpen.value = !isDialogEditEducationHistoryOpen.value;
    }

    const openDialogEditSkills = (event, key) => {
      clickedKey.value = key;
      clickedItem.value = JSON.parse(JSON.stringify(props.educationHistory[key]))
      skillsOfClickedItem.value = clickedItem.value.skill;
      isDialogEditSkillsOpen.value = !isDialogEditSkillsOpen.value;
    }

    const callMultiAction = (event, key) => {
      if(selectedMultiAction.value === "Löschen"){
        openDialogDeleteEducationHistory(event, key)
      }
      else if(selectedMultiAction.value === "Verschieben"){
        openDialogMoveEducationHistory(event, key)
      }
    }

    const openDialogEditFreetexts = (event, key) => {
      clickedKey.value = key;
      clickedItem.value = JSON.parse(JSON.stringify(props.educationHistory[key]))
      freetextsOfClickedItem.value = clickedItem.value.freetexts;
      isDialogEditFreetextsOpen.value = !isDialogEditFreetextsOpen.value;
    }

    const openDialogDeleteEducationHistory = (event, key) => {
      if(selectedMultiAction.value === "Löschen"){
        clickedKey.value = selectedTableData.value.map(item => item.key);
      }
      else{
        clickedKey.value = key;
      }
      isDialogDeleteEducationHistoryOpen.value = true;
    }

    const deleteEducationHistoryCheck = () => {
      emit("deleteEducationHistoryCheck", clickedKey.value)
      closeDialogDeleteEducationHistory();
    };

    const closeDialogDeleteEducationHistory = () => {
      isDialogDeleteEducationHistoryOpen.value = false;
      clickedItem.value = {};
      nextTick(() => {
        selectedMultiAction.value = 0;
      })
    }

    const openDialogMoveEducationHistory = (event, key) => {
      if(selectedMultiAction.value === "Verschieben"){
        clickedKey.value = selectedTableData.value.map(item => item.key);
      }
      else{
        clickedKey.value = key;
      }
      isDialogMoveEducationHistoryOpen.value = true;
    }

    const moveEducationHistoryCheck = () => {
      emit("moveEducationHistoryCheck", clickedKey.value)
      closeDialogMoveEducationHistory();
    };

    const closeDialogMoveEducationHistory = () => {
      isDialogMoveEducationHistoryOpen.value = false;
      clickedItem.value = {};
      nextTick(() => {
        selectedMultiAction.value = 0;
      })
    }

    const createEducationHistoryCheck = (educationHistory) => {
      emit("createEducationHistoryCheck", educationHistory)
    }

    const editEducationHistoryCheck = (educationHistory) => {
      emit("editEducationHistoryCheck", clickedKey.value, educationHistory)
    }

    const sortEducationHistoryCheck = (items) => {
      emit("sortEducationHistoryCheck",items)
    }

    const editEducationHistorySkillCheck = (skills) => {
      clickedItem.value.skill = skills;
      emit("editEducationHistoryCheck", clickedKey.value, clickedItem.value)
    }

    const editEducationHistoryFreetextsCheck = (freetexts) => {
      clickedItem.value.freetexts = freetexts;
      emit("editEducationHistoryCheck", clickedKey.value, clickedItem.value)
    }

    return {
      action,
      refreshTable,
      createEducationHistoryCheck,
      editEducationHistoryCheck,
      sortEducationHistoryCheck,
      editEducationHistorySkillCheck,
      editEducationHistoryFreetextsCheck,
      isDialogEditEducationHistoryOpen,
      isDialogEditSkillsOpen,
      isDialogEditFreetextsOpen,
      isDialogDeleteEducationHistoryOpen,
      isDialogMoveEducationHistoryOpen,
      clickedKey,
      clickedItem,
      skillsOfClickedItem,
      freetextsOfClickedItem,
      onCreateClick,
      onEditClick,
      openDialogEditSkills,
      openDialogEditFreetexts,
      openDialogDeleteEducationHistory,
      closeDialogDeleteEducationHistory,
      deleteEducationHistoryCheck,
      openDialogMoveEducationHistory,
      moveEducationHistoryCheck,
      closeDialogMoveEducationHistory,
      callMultiAction,
      tableColumns,
      searchQuery,
      options,
      totalInvoiceListTable,
      invoiceListTable,
      loading,
      actions,
      selectedTableData,
      selectedMultiAction,
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiEyeOutline,
        mdiAlphaFBoxOutline,
        mdiAlphaTBoxOutline,
        mdiArrowAll,
        mdiSwapHorizontalBold,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
#invoice-list {
  .invoice-list-actions {
    max-width: 7.81rem;
  }

  .invoice-list-search {
    max-width: 10.625rem;
  }

  .invoice-list-status {
    max-width: 11.3rem;
  }
}

.v-application .text-no-wrap {
  white-space: break-spaces !important;
}
</style>
