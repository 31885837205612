<template>
  <v-dialog
    v-model="isDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-dialog-open',false)"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Bearbeite Freitexte
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Änderungen können nicht mehr rückgängig gemacht werden.
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">
          <v-row>

            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                v-model="itemToEdit.mobility"
                outlined
                dense
                label="Mobilität"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <v-textarea
                v-model="itemToEdit.interestingFacts"
                outlined
                label="Wissenswertes"
                rows="20"
                hide-details="auto"
                placeholder="Wissenswertes"
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <v-textarea
                v-model="itemToEdit.accessibility"
                outlined
                label="Verfügbarkeit"
                rows="5"
                hide-details="auto"
                placeholder="Verfügbarkeit"
              ></v-textarea>
            </v-col>

            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                v-model="itemToEdit.conditions"
                outlined
                dense
                label="Konditionen"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="onSubmit"
              >
                Speichern
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="onReset"
              >
                Abbrechen
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {ref,watch} from '@vue/composition-api'

export default {
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
    freetexts: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {

    const itemToEdit = ref({});
    itemToEdit.value = JSON.parse(JSON.stringify(props.freetexts))

    watch(() => props.isDialogOpen, (newValue) => {
      if(props.isDialogOpen){
        itemToEdit.value = JSON.parse(JSON.stringify(props.freetexts))
      }
    });

    const onReset = () => {
      emit('update:is-dialog-open',false)
      itemToEdit.value = JSON.parse(JSON.stringify(props.freetexts))
    }
    const onSubmit = () => {
      emit('update:is-dialog-open',false)
      emit('editFreetextsCheck',itemToEdit.value)
    }

    return {
      itemToEdit,
      onSubmit,
      onReset,
    }
  },
}
</script>
