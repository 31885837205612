<template>
  <div class="user-tab-billings-plans">
    <v-card class="mb-7">
      <v-card-text>
        <v-card outlined class="mb-4">
          <v-card-title>PDF-Layout</v-card-title>
          <v-card-text class="">
            <div class="mb-3 mb-sm-0">
              <div class="text--primary font-weight-medium mt-2">
                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-list-item class="px-0">
                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <v-switch v-model="pdfSettingsCopy.pagebreak.interestingFacts"
                                  @click="editPdfSettingsCheck">
                          <template v-slot:label>
                            <div class="me-2">
                              <v-list-item-title class="text-sm">
                                Wissenswertes
                              </v-list-item-title>
                            </div>
                          </template>
                        </v-switch>
                      </div>
                    </v-list-item>
                    <v-list-item class="px-0">
                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <v-switch v-model="pdfSettingsCopy.pagebreak.accessibility"
                                  @click="editPdfSettingsCheck">
                          <template v-slot:label>
                            <div class="me-2">
                              <v-list-item-title class="text-sm">
                                Verfügbarkeit
                              </v-list-item-title>
                            </div>
                          </template>
                        </v-switch>
                      </div>
                    </v-list-item>
                    <v-list-item class="px-0">
                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <v-switch v-model="pdfSettingsCopy.pagebreak.conditions"
                                  @click="editPdfSettingsCheck">
                          <template v-slot:label>
                            <div class="me-2">
                              <v-list-item-title class="text-sm">
                                Konditionen
                              </v-list-item-title>
                            </div>
                          </template>
                        </v-switch>
                      </div>
                    </v-list-item>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-list-item class="px-0">
                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <v-switch v-model="pdfSettingsCopy.pagebreak.workingHistory"
                                  @click="editPdfSettingsCheck">
                          <template v-slot:label>
                            <div class="me-2">
                              <v-list-item-title class="text-sm">
                                Werdegang
                              </v-list-item-title>
                            </div>
                          </template>
                        </v-switch>
                      </div>
                    </v-list-item>
                    <v-list-item class="px-0">
                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <v-switch v-model="pdfSettingsCopy.pagebreak.educationHistory"
                                  @click="editPdfSettingsCheck">
                          <template v-slot:label>
                            <div class="me-2">
                              <v-list-item-title class="text-sm">
                                Ausbildungen/Weiterbildungen
                              </v-list-item-title>
                            </div>
                          </template>
                        </v-switch>
                      </div>
                    </v-list-item>
                    <v-list-item class="px-0">
                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <v-switch v-model="pdfSettingsCopy.pagebreak.otherSkills"
                                  @click="editPdfSettingsCheck">
                          <template v-slot:label>
                            <div class="me-2">
                              <v-list-item-title class="text-sm">
                                Sonstige Qualifikationen
                              </v-list-item-title>
                            </div>
                          </template>
                        </v-switch>
                      </div>
                    </v-list-item>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-list-item class="px-0">
                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <v-switch v-model="pdfSettingsCopy.pagebreak.competences"
                                  @click="editPdfSettingsCheck">
                          <template v-slot:label>
                            <div class="me-2">
                              <v-list-item-title class="text-sm">
                                Kompetenzen
                              </v-list-item-title>
                            </div>
                          </template>
                        </v-switch>
                      </div>
                    </v-list-item>
                    <v-list-item class="px-0">
                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <v-switch v-model="pdfSettingsCopy.pagebreak.interests"
                                  @click="editPdfSettingsCheck">
                          <template v-slot:label>
                            <div class="me-2">
                              <v-list-item-title class="text-sm">
                                Interessen
                              </v-list-item-title>
                            </div>
                          </template>
                        </v-switch>
                      </div>
                    </v-list-item>
                    <v-list-item class="px-0">
                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <v-switch v-model="pdfSettingsCopy.pagebreak.languages"
                                  @click="editPdfSettingsCheck">
                          <template v-slot:label>
                            <div class="me-2">
                              <v-list-item-title class="text-sm">
                                Sprachen
                              </v-list-item-title>
                            </div>
                          </template>
                        </v-switch>
                      </div>
                    </v-list-item>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card outlined class="mb-4">
          <v-card-text class="d-flex align-sm-center flex-column flex-sm-row">
            <div class="mb-3 mb-sm-0">
              <div class="text--primary font-weight-medium mt-2">
                <v-switch label="Sonstige Qualifikationen" dense v-model="pdfSettingsCopy.showOtherSkillsInPdf" @click="editPdfSettingsCheck"></v-switch>
              </div>
              <span>
                <span class="text-xs">{{getTextOfOthers()}}</span>
              </span>
            </div>
            <v-spacer></v-spacer>
            <div class="text-sm-right">
              <v-btn color="primary" outlined class="m-5" @click="openDialogEditOthers()">Editieren</v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-card outlined class="mb-4">
          <v-card-text class="d-flex align-sm-center flex-column flex-sm-row">
            <div class="mb-3 mb-sm-0">
              <div class="text--primary font-weight-medium mt-2">
                <v-switch label="Kompetenzen" dense v-model="pdfSettingsCopy.showCompetencesInPdf" @click="editPdfSettingsCheck"></v-switch>
              </div>
              <span>
                <span class="text-xs">{{getTextOfCompetences()}}</span>
              </span>
            </div>
            <v-spacer></v-spacer>
            <div class="text-sm-right">
              <v-btn color="primary" outlined class="m-5" @click="openDialogEditCompetences()">Editieren</v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-card outlined class="mb-4">
          <v-card-text class="d-flex align-sm-center flex-column flex-sm-row">
            <div class="mb-3 mb-sm-0">
              <div class="text--primary font-weight-medium mt-2">
                <v-switch label="Interessen" dense v-model="pdfSettingsCopy.showInterestsInPdf" @click="editPdfSettingsCheck"></v-switch>
              </div>
              <span>
                <span class="text-xs">{{getTextOfInterests()}}</span>
              </span>
            </div>
            <v-spacer></v-spacer>
            <div class="text-sm-right">
              <v-btn color="primary" outlined class="m-5" @click="openDialogEditInterests()">Editieren</v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-card outlined class="mb-4">
          <v-card-text class="d-flex align-sm-center flex-column flex-sm-row">
            <div class="mb-3 mb-sm-0">
              <div class="text--primary font-weight-medium mt-2">
                <v-switch label="Sprachen" dense v-model="pdfSettingsCopy.showLanguagesInPdf" @click="editPdfSettingsCheck"></v-switch>
              </div>
              <span>
                <span class="text-xs">{{getTextOfLanguages()}}</span>
              </span>
            </div>
            <v-spacer></v-spacer>
            <div class="text-sm-right">
              <v-btn color="primary" outlined class="m-5" @click="openDialogEditLanguages()">Editieren</v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-card outlined class="mb-4">
          <v-card-text class="d-flex align-sm-center flex-column flex-sm-row">
            <div class="mb-3 mb-sm-0">
              <div class="text--primary font-weight-medium mt-2">
                <span class="text-base me-2">Einsatzgebiete</span>
              </div>
              <span>
                <span class="text-xs">{{getTextOfOperationAreas()}}</span>
              </span>
            </div>
            <v-spacer></v-spacer>
            <div class="text-sm-right">
              <v-btn color="primary" outlined class="m-5" @click="openDialogEditOperationAreas()">Editieren</v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-card outlined class="mb-4">
          <v-card-text class="d-flex align-sm-center flex-column flex-sm-row">
            <div class="mb-3 mb-sm-0">
              <div class="text--primary font-weight-medium mt-2">
                <span class="text-base me-2">Branchen</span>
              </div>
              <span>
                <span class="text-xs">{{getTextOfIndustries()}}</span>
              </span>
            </div>
            <v-spacer></v-spacer>
            <div class="text-sm-right">
              <v-btn color="primary" outlined class="m-5" @click="openDialogEditIndustries()">Editieren</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <dialog-edit-code-list
      domain="Skill"
      title="Sonstige Qualifikationen"
      label="Fähigkeit"
      :is-dialog-open.sync="isDialogEditOthersOpen"
      :list.sync="additionalInformation.undatedSkill"
      @save="editOthersCheck"
    ></dialog-edit-code-list>

    <dialog-edit-freetext
      title="Kompetenzen"
      label="Kompetenzen"
      :is-dialog-open.sync="isDialogEditCompetencesOpen"
      :item.sync="additionalInformation.competences"
      @save="editCompetencesCheck"
    ></dialog-edit-freetext>

    <dialog-edit-freetext
      title="Interessen"
      label="Interessen"
      :is-dialog-open.sync="isDialogEditInterestsOpen"
      :item.sync="additionalInformation.interests"
      @save="editInterestsCheck"
    ></dialog-edit-freetext>

    <dialog-edit-language-list
      :is-dialog-open.sync="isDialogEditLanguagesOpen"
      :list.sync="additionalInformation.language"
      @save="editLanguagesCheck"
    ></dialog-edit-language-list>

    <dialog-edit-code-list
      domain="OperationArea"
      title="Einsatzgebiete"
      label="Einsatzgebiet"
      :is-dialog-open.sync="isDialogEditOperationAreasOpen"
      :list.sync="additionalInformation.undatedOperationArea"
      @save="editOperationAreasCheck"
    ></dialog-edit-code-list>

    <dialog-edit-code-list
      domain="NACE"
      title="Branchen"
      label="Branche"
      :is-dialog-open.sync="isDialogEditIndustriesOpen"
      :list.sync="additionalInformation.undatedIndustry"
      @save="editIndustriesCheck"
    ></dialog-edit-code-list>

  </div>
</template>

<script>
import DialogEditCodeList from '@/views/candidate/user-view/DialogEditCodeList.vue'
import DialogEditLanguageList from '@/views/candidate/user-view/DialogEditLanguageList.vue'
import DialogEditFreetext from '@/views/candidate/user-view/DialogEditFreetext.vue'
import { mdiPlus, mdiDeleteOutline, mdiSquareEditOutline } from '@mdi/js'
import {getCurrentInstance, ref, watch} from '@vue/composition-api'

export default {
  components:{
    DialogEditCodeList,
    DialogEditLanguageList,
    DialogEditFreetext
  },
  props: {
    additionalInformation: {
      type: Object,
      required: true,
    },
    pdfSettings: {
      type: Object,
      required: true,
    }
  },
  setup(props,{emit}) {

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    const additionalInfoCopy = ref({});
    additionalInfoCopy.value = JSON.parse(JSON.stringify(props.additionalInformation))

    watch(() => props.additionalInformation, (newValue) => {
      additionalInfoCopy.value = JSON.parse(JSON.stringify(newValue))
    });

    let isDialogEditOthersOpen = ref(false)
    let isDialogEditCompetencesOpen = ref(false)
    let isDialogEditInterestsOpen = ref(false)
    let isDialogEditLanguagesOpen = ref(false)
    let isDialogEditOperationAreasOpen = ref(false)
    let isDialogEditIndustriesOpen = ref(false)

    const openDialogEditOthers = () => {
      additionalInfoCopy.value = JSON.parse(JSON.stringify(props.additionalInformation))
      isDialogEditOthersOpen.value = true;
    }

    const openDialogEditCompetences = () => {
      isDialogEditCompetencesOpen.value = true;
    }

    const openDialogEditInterests = () => {
      isDialogEditInterestsOpen.value = true;
    }

    const openDialogEditLanguages = () => {
      isDialogEditLanguagesOpen.value = true;
    }

    const openDialogEditOperationAreas = () => {
      isDialogEditOperationAreasOpen.value = true;
    }

    const openDialogEditIndustries = () => {
      isDialogEditIndustriesOpen.value = true;
    }

    const getTextOfCode = (domain,code) => {
      let joinvisionDomains = vm.$domainCodes;
      if(joinvisionDomains[domain] !== undefined){
        let array= joinvisionDomains[domain].filter(item => item.code === code);
        if(array[0] === undefined) return "";
        return array[0].de;
      }
    }

    const getTextOfOthers = () => {
      return props.additionalInformation.undatedSkill.map((item) => getTextOfCode('Skill',item.code)).join(", ");
    }

    const getTextOfCompetences = () =>{
      return nl2br(props.additionalInformation.competences)
    }

    const getTextOfInterests = () =>{
      return nl2br(props.additionalInformation.interests)
    }

    const getTextOfLanguages = () => {
      return props.additionalInformation.language.map((item) => getTextOfCode('ISOLanguage',item.code)).join(", ");
    }

    const getTextOfOperationAreas = () => {
      return props.additionalInformation.undatedOperationArea.map((item) => getTextOfCode('OperationArea',item.code)).join(", ");
    }

    const getTextOfIndustries = () => {
      return props.additionalInformation.undatedIndustry.map((item) => getTextOfCode('NACE',item.code)).join(", ");
    }

    const editOthersCheck = (item) => {
      additionalInfoCopy.value.undatedSkill = item;
      emit("editAdditionalInformationCheck",additionalInfoCopy.value)
    }

    const editCompetencesCheck = (item) => {
      additionalInfoCopy.value.competences = item;
      emit("editAdditionalInformationCheck",additionalInfoCopy.value)
    }

    const editInterestsCheck = (item) => {
      additionalInfoCopy.value.interests = item;
      emit("editAdditionalInformationCheck",additionalInfoCopy.value)
    }

    const editLanguagesCheck = (item) => {
      additionalInfoCopy.value.language = item;
      emit("editAdditionalInformationCheck",additionalInfoCopy.value)
    }

    const editOperationAreasCheck = (item) => {
      additionalInfoCopy.value.undatedOperationArea = item;
      emit("editAdditionalInformationCheck",additionalInfoCopy.value)
    }

    const editIndustriesCheck = (item) => {
      additionalInfoCopy.value.undatedIndustry = item;
      emit("editAdditionalInformationCheck",additionalInfoCopy.value)
    }

    const nl2br = (D, replaceMode, isXhtml) => {
      return D;
      var breakTag = (isXhtml) ? '<br />' : '<br>';
      var replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2';
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
    }


    const pdfSettingsCopy = ref({});
    pdfSettingsCopy.value = JSON.parse(JSON.stringify(props.pdfSettings))

    watch(() => props.pdfSettings, (newValue) => {
      pdfSettingsCopy.value = JSON.parse(JSON.stringify(newValue))
    });

    const editPdfSettingsCheck = () => {
      emit("editPdfSettingsCheck", pdfSettingsCopy.value)
    }



    return {
      isDialogEditOthersOpen,
      isDialogEditCompetencesOpen,
      isDialogEditInterestsOpen,
      isDialogEditLanguagesOpen,
      isDialogEditOperationAreasOpen,
      isDialogEditIndustriesOpen,
      openDialogEditOthers,
      openDialogEditCompetences,
      openDialogEditInterests,
      openDialogEditLanguages,
      openDialogEditOperationAreas,
      openDialogEditIndustries,
      nl2br,
      getTextOfCode,
      getTextOfOthers,
      getTextOfCompetences,
      getTextOfInterests,
      getTextOfLanguages,
      getTextOfOperationAreas,
      getTextOfIndustries,
      editOthersCheck,
      editCompetencesCheck,
      editInterestsCheck,
      editLanguagesCheck,
      editOperationAreasCheck,
      editIndustriesCheck,
      pdfSettingsCopy,
      editPdfSettingsCheck,
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiSquareEditOutline,
      },
    }
  },
}
</script>
