<template>
  <v-dialog
    v-model="isDialogOpen"
    max-width="650px"
    @click:outside="onReset"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Sprachen
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Änderungen können nicht mehr rückgängig gemacht werden.
      </v-card-text>
      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">

            <v-row v-for="(itemToEdit,itemToEditIndex) in listCopy" :key="itemToEditIndex" >
              <v-col
                cols="12"
                sm="5"
              >
                <v-select
                  v-model="itemToEdit.code"
                  label="Sprache"
                  outlined
                  dense
                  :items="domainCodes"
                  item-text="de"
                  item-value="code"
                  class="invoice-list-actions "
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  v-model="itemToEdit.level"
                  label="Level"
                  outlined
                  dense
                  :items="levels"
                  item-text="key"
                  item-value="value"
                  class="invoice-list-actions "
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="1"
              >
              <v-btn
                color="second"
                class=""
                @click="onDeleteClick(itemToEditIndex)"
              >
                <v-icon
                  size="18"
                  class="me-1"
                >
                  {{ icons.mdiMinus }}
                </v-icon>
                </v-btn>
              </v-col>
            </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="1"
            >
            <v-btn
              color="second"
              class=""
              @click="onCreateClick"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"

                type="submit"
                @click.prevent="onSubmit"
              >
                Speichern
              </v-btn>
              <v-btn
                outlined
                color="secondary"
                @click.prevent="onReset"
              >
                Abbrechen
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import {getCurrentInstance, ref, watch} from "@vue/composition-api";
import {
  mdiPlus,
  mdiMinus,
} from '@mdi/js'

export default {
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
    list: {
      type: Array,
      default() {
        return []
      },
      required: true,
    }
  },
  setup(props, { emit }) {

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    const levels = [
      {key:"-" , value:""},
      {key:"A1" , value:"A1"},
      {key:"A2" , value:"A2"},
      {key:"B1" , value:"B1"},
      {key:"B2" , value:"B2"},
      {key:"C1" , value:"C1"},
      {key:"C2" , value:"C2"},
      {key:"Muttersprache" , value:"Muttersprache"},
    ];

    const listCopy = ref([]);
    listCopy.value = Object.values(props.list);
    watch(() => props.list, (newValue,oldValue) => {
      if(props.isDialogOpen){
        listCopy.value = Object.values(newValue);
      }
    });

    const domainCodes = ref({});
    watch(() => props.isDialogOpen, () => {
      if(props.isDialogOpen){
        domainCodes.value = vm.$domainCodes["ISOLanguage"].filter(item => item.domain === "ISOLanguage");
      }
      else{
        domainCodes.value = [];
      }
    });

    const onDeleteClick = (itemToEditIndex) => {
      listCopy.value = listCopy.value.filter((item,key) => key != itemToEditIndex);
    }

    const onCreateClick = () => {
      listCopy.value.push({
        code: "",
        name: "",
        weight: ""
      })
    }

    const onReset = () => {
      emit('update:is-dialog-open',false)
      listCopy.value = Object.values(props.list);
    }

    const onSubmit = () => {
      emit('update:is-dialog-open',false)
      emit('save',listCopy.value)
    }

    return {
      domainCodes,
      levels,
      listCopy,
      onCreateClick,
      onDeleteClick,
      onSubmit,
      onReset,
      icons: {
        mdiPlus,
        mdiMinus
      },
    }
  },
}
</script>

<style>
.v-textarea textarea {
  line-height: 1.2rem;
}
</style>
