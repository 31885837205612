<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isBioDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-bio-dialog-open',false)"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Bearbeite Persönliche Daten
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Änderungen können nicht mehr rückgängig gemacht werden.
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">

          <v-row>


            <v-col
              cols="12"
              sm="4"
            >
              <v-select
                v-model="itemToEdit.gender.code"
                label="Geschlecht"
                outlined
                dense
                :items="joinvisionDomains.Gender"
                item-text="de"
                item-value="code"
                class="invoice-list-actions "
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="itemToEdit.title"
                outlined
                dense
                label="Titel"
              ></v-text-field>
            </v-col>





            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="itemToEdit.firstname"
                outlined
                dense
                label="Vorname"
              ></v-text-field>
            </v-col>


            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="itemToEdit.lastname"
                outlined
                dense
                label="Nachname"
              ></v-text-field>
            </v-col>


            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                v-model="itemToEdit.currentJob"
                outlined
                dense
                label="Aktueller Beruf"
              ></v-text-field>
            </v-col>


            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                v-model="itemToEdit.address.street"
                outlined
                dense
                label="Straße"
              ></v-text-field>
            </v-col>


            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="itemToEdit.address.postcode"
                outlined
                dense
                label="PLZ"
              ></v-text-field>
            </v-col>


            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="itemToEdit.address.city"
                outlined
                dense
                label="Ort"
              ></v-text-field>
            </v-col>


            <v-col
              cols="12"
              sm="4"
            >
              <v-select
                v-model="itemToEdit.address.country.code"
                label="Land"
                outlined
                dense
                :items="joinvisionDomains.ISOCountry"
                item-text="de"
                item-value="code"
                class="invoice-list-actions "
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="itemToEdit.address.state"
                outlined
                dense
                label="Bundesland"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="itemToEdit.isced.code"
                label="ISCED"
                outlined
                dense
                :items="joinvisionDomains.ISCED"
                item-text="de"
                item-value="code"
                class="invoice-list-actions "
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="itemToEdit.nationality.code"
                label="Nationalität"
                outlined
                dense
                :items="joinvisionDomains.ISOCountry"
                item-text="de"
                item-value="code"
                class="invoice-list-actions "
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="itemToEdit.birthdate"
                outlined
                dense
                label="Geburtsdatum"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="itemToEdit.email"
                outlined
                dense
                label="E-Mail"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="itemToEdit.phoneNumber"
                outlined
                dense
                label="Telefon"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="onSubmit"
              >
                Speichern
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="onReset"
              >
                Abbrechen
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {getCurrentInstance, ref, toRaw, watch} from '@vue/composition-api'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    personalInformation: {
      type: Object,
      required: true,
    }
  },

  setup(props, { emit }) {

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    let joinvisionDomains = ref({
      CERF: [],
      CivilState: [],
      Contract: [],
      EducationType: [],
      Employment: [],
      Experience: [],
      Gender: [],
      ISCED: [],
      ISOCountry: [],
      ISOLanguage: [],
      NACE: [],
      OperationArea: [],
      Position: [],
      Skill: [],
    });

    const loadDomainCodes = (domain) => {
      joinvisionDomains.value[domain] = vm.$domainCodes[domain].filter(item => item.domain === domain);
    }

    const truncateDomainCodes = () => {
      joinvisionDomains.value = [];
    }

    const itemToEdit = ref({});
    itemToEdit.value = JSON.parse(JSON.stringify(props.personalInformation))

    watch(() => props.isBioDialogOpen, (newValue) => {
      if(props.isBioDialogOpen){
        loadDomainCodes("Gender")
        loadDomainCodes("ISOCountry")
        loadDomainCodes("ISCED")
        itemToEdit.value = JSON.parse(JSON.stringify(props.personalInformation))
      }
      else{
        truncateDomainCodes()
      }
    });

    const onReset = () => {
      emit('update:is-bio-dialog-open',false)
      itemToEdit.value = JSON.parse(JSON.stringify(props.personalInformation))
    }
    const onSubmit = () => {
      emit('update:is-bio-dialog-open',false)
      emit('editPersonalInformationCheck',itemToEdit.value)
    }

    return {
      joinvisionDomains,
      loadDomainCodes,
      truncateDomainCodes,
      itemToEdit,
      onSubmit,
      onReset,
    }
  },
}
</script>
