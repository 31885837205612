<template>
  <div class="user-tab-connection">
    <v-card class="mb-7">
      <v-card-subtitle>
        Definieren Sie hier die Seitenumbrüche nach den Hauptsektionen.
      </v-card-subtitle>
      <v-card-text class="pb-2">
        <v-list
          dense
          class="py-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              <v-list-item class="px-0">
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <v-switch v-model="pdfSettingsCopy.pagebreak.interestingFacts"
                            @click="editPdfSettingsCheck">
                    <template v-slot:label>
                      <div class="me-2">
                        <v-list-item-title class="text-sm">
                          Wissenswertes
                        </v-list-item-title>
                      </div>
                    </template>
                  </v-switch>
                </div>
              </v-list-item>
              <v-list-item class="px-0">
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <v-switch v-model="pdfSettingsCopy.pagebreak.accessibility"
                            @click="editPdfSettingsCheck">
                    <template v-slot:label>
                      <div class="me-2">
                        <v-list-item-title class="text-sm">
                          Verfügbarkeit
                        </v-list-item-title>
                      </div>
                    </template>
                  </v-switch>
                </div>
              </v-list-item>
              <v-list-item class="px-0">
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <v-switch v-model="pdfSettingsCopy.pagebreak.conditions"
                            @click="editPdfSettingsCheck">
                    <template v-slot:label>
                      <div class="me-2">
                        <v-list-item-title class="text-sm">
                          Konditionen
                        </v-list-item-title>
                      </div>
                    </template>
                  </v-switch>
                </div>
              </v-list-item>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-list-item class="px-0">
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <v-switch v-model="pdfSettingsCopy.pagebreak.workingHistory"
                            @click="editPdfSettingsCheck">
                    <template v-slot:label>
                      <div class="me-2">
                        <v-list-item-title class="text-sm">
                          Werdegang
                        </v-list-item-title>
                      </div>
                    </template>
                  </v-switch>
                </div>
              </v-list-item>
              <v-list-item class="px-0">
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <v-switch v-model="pdfSettingsCopy.pagebreak.educationHistory"
                            @click="editPdfSettingsCheck">
                    <template v-slot:label>
                      <div class="me-2">
                        <v-list-item-title class="text-sm">
                          Ausbildungen/Weiterbildungen
                        </v-list-item-title>
                      </div>
                    </template>
                  </v-switch>
                </div>
              </v-list-item>
              <v-list-item class="px-0">
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <v-switch v-model="pdfSettingsCopy.pagebreak.otherSkills"
                            @click="editPdfSettingsCheck">
                    <template v-slot:label>
                      <div class="me-2">
                        <v-list-item-title class="text-sm">
                          Sonstige Qualifikationen
                        </v-list-item-title>
                      </div>
                    </template>
                  </v-switch>
                </div>
              </v-list-item>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-list-item class="px-0">
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <v-switch v-model="pdfSettingsCopy.pagebreak.competences"
                            @click="editPdfSettingsCheck">
                    <template v-slot:label>
                      <div class="me-2">
                        <v-list-item-title class="text-sm">
                          Kompetenzen
                        </v-list-item-title>
                      </div>
                    </template>
                  </v-switch>
                </div>
              </v-list-item>
              <v-list-item class="px-0">
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <v-switch v-model="pdfSettingsCopy.pagebreak.interests"
                            @click="editPdfSettingsCheck">
                    <template v-slot:label>
                      <div class="me-2">
                        <v-list-item-title class="text-sm">
                          Interessen
                        </v-list-item-title>
                      </div>
                    </template>
                  </v-switch>
                </div>
              </v-list-item>
              <v-list-item class="px-0">
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <v-switch v-model="pdfSettingsCopy.pagebreak.languages"
                            @click="editPdfSettingsCheck">
                    <template v-slot:label>
                      <div class="me-2">
                        <v-list-item-title class="text-sm">
                          Sprachen
                        </v-list-item-title>
                      </div>
                    </template>
                  </v-switch>
                </div>
              </v-list-item>
            </v-col>
          </v-row>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {ref, watch} from "@vue/composition-api"
export default {
  props: {
    pdfSettings: {
      type: Object,
      required: true,
    }
  },
  setup(props, {emit}){

    const pdfSettingsCopy = ref({});
    pdfSettingsCopy.value = JSON.parse(JSON.stringify(props.pdfSettings))

    watch(() => props.pdfSettings, (newValue) => {
      pdfSettingsCopy.value = JSON.parse(JSON.stringify(newValue))
    });

    const editPdfSettingsCheck = () => {
      emit("editPdfSettingsCheck", pdfSettingsCopy.value)
    }

    return {
      pdfSettingsCopy,
      editPdfSettingsCheck
    }
  },
}
</script>
