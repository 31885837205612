<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <user-bio-panel
          :personal-information="form.inputData.strukturierteDatenJson.personalInformation"
          :photo="form.inputData.strukturierteDatenJson.photo"
          :headhunter="form.inputData.strukturierteDatenJson.headhunter"
          :freetexts="form.inputData.strukturierteDatenJson.freetexts"
          :state="form.inputData.status"
          :stateData="form.stateData"
          :idThJobDefinition="form.inputData.idThJobDefinition"
          :jobDefinitions="jobDefinitions"
          :files="form.inputData.files"
          @editPersonalInformationCheck="editPersonalInformationCheck"
          @editJobDefinitionCheck="editJobDefinitionCheck"
          @editFreetextsCheck="editFreetextsCheck"
          @editPhotoCheck="editPhotoCheck"
          @editState="editState"
          @editStateData="editStateData"
          @editHeadhunter="editHeadhunter"
          @editFilesCheck="editFilesCheck"
        ></user-bio-panel>
      </v-col>
      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >

          <v-tab-item>
            <user-tab-overview
              :freetexts="form.inputData.strukturierteDatenJson.freetexts"
              :personal-information="form.inputData.strukturierteDatenJson.personalInformation"
              :idThJobDefinition="form.inputData.idThJobDefinition"
              :jobDefinitions="jobDefinitions"
              :reichweiteInKm="form.inputData.reichweiteInKm"
              @editPersonalInformationCheck="editPersonalInformationCheck"
              @editFreetextsCheck="editFreetextsCheck"
              @editJobDefinitionCheck="editJobDefinitionCheck"
              @editCityAndRangeCheck="editCityAndRangeCheck"
            ></user-tab-overview>
          </v-tab-item>

          <v-tab-item>
            <list-working-history
              :working-history="form.inputData.strukturierteDatenJson.work.phase"
              @createWorkingHistoryCheck="createWorkingHistoryCheck"
              @editWorkingHistoryCheck="editWorkingHistoryCheck"
              @sortWorkingHistoryCheck="sortWorkingHistoryCheck"
              @deleteWorkingHistoryCheck="deleteWorkingHistoryCheck"
              @moveWorkingHistoryCheck="moveWorkingHistoryCheck"
              :reload-table="reloadTable"
              @tableReloaded="reloadTable = false"
            ></list-working-history>
          </v-tab-item>
          <v-tab-item>
            <list-education-history
              :education-history="form.inputData.strukturierteDatenJson.education.phase"
              @createEducationHistoryCheck="createEducationHistoryCheck"
              @editEducationHistoryCheck="editEducationHistoryCheck"
              @sortEducationHistoryCheck="sortEducationHistoryCheck"
              @deleteEducationHistoryCheck="deleteEducationHistoryCheck"
              @moveEducationHistoryCheck="moveEducationHistoryCheck"
              :reload-table="reloadTable"
              @tableReloaded="reloadTable = false"

            ></list-education-history>
          </v-tab-item>
          <v-tab-item>
            <user-tab-additional-informations
              :additional-information="form.inputData.strukturierteDatenJson.additionalInformation"
              :pdf-settings="form.inputData.strukturierteDatenJson.pdfSettings"
              @editAdditionalInformationCheck="editAdditionalInformationCheck"
              @editPdfSettingsCheck="editPdfSettingsCheck"



            ></user-tab-additional-informations>
          </v-tab-item>
<!--          <v-tab-item>
            <user-tab-charts
              :additional-images="form.inputData.strukturierteDatenJson.additionalImages"
            ></user-tab-charts>
          </v-tab-item>-->
<!--          <v-tab-item>
            <user-tab-pdf-layout
              :pdf-settings="form.inputData.strukturierteDatenJson.pdfSettings"
              @editPdfSettingsCheck="editPdfSettingsCheck"
            ></user-tab-pdf-layout>
          </v-tab-item>-->

          <v-tab-item v-if="form.inputData.idThJobDefinition > 0">
            <user-tab-interview
              :interview="form.inputData.interview"
              :jobTitle="form.inputData.job"
              @editInterviewCheck="editInterviewCheck"
            ></user-tab-interview>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {getCurrentInstance, nextTick, onMounted, onUnmounted, ref} from '@vue/composition-api'
import router from '@/router'
import {
  mdiBriefcaseOutline,
  mdiSchoolOutline,
  mdiBookmarkOutline,
  mdiChartLine,
  mdiFilePdfBox,
  mdiChatQuestionOutline,
  mdiHomeOutline
} from '@mdi/js'
import UserBioPanel from './user-bio-panel/UserBioPanel.vue'
import UserTabOverview from './user-tab-overview/UserTabOverview.vue'
import ListWorkingHistory from './user-tab-working-history/ListWorkingHistory.vue'
import ListEducationHistory from './user-tab-education-history/ListEducationHistory.vue'
import UserTabAdditionalInformations from './user-tab-additional-informations/UserTabAdditionalInformations.vue'
import UserTabCharts from './user-tab-charts/UserTabCharts.vue'
import UserTabPdfLayout from './user-tab-pdf-layout/UserTabPdfLayout.vue'
import UserTabInterview from './user-tab-interview/UserTabInterview.vue'
import axios from "@axios";

export default {
  components: {
    UserBioPanel,
    ListWorkingHistory,
    ListEducationHistory,
    UserTabAdditionalInformations,
    UserTabCharts,
    UserTabPdfLayout,
    UserTabInterview,
    UserTabOverview,
  },
  setup(props) {

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    const reloadTable = ref(false);
    const form = ref({});
    form.value = {
      url:'/api/bewerber/',
      photo:null,
      aktion: (router.currentRoute.params.idThBewerber !== undefined ? 'editCandidateCheck' : 'createCandidateCheck'),
      stateData: {
        angabeDsgvo : false,
        angabeSuchparameter : false,
        angabeUnterlagen : false,
        angabeOeffentlich : false,
      },
      inputData: {
        idThJobDefinition: '',
        reichweiteInKm: "30",
        vorname: '',
        name: '',
        mobilitaet:'',
        wissenswertes:'',
        verfuegbarkeit:'',
        konditionen:'',
        idThBewerber: router.currentRoute.params.idThBewerber,
        status:"beworben",
        interview:[],
        files:[],
        selectedFiles:[],
        deletedFiles:[],
        changedFiles:[],
        strukturierteDatenJson:{
          headhunter:"",
          personalInformation: {
            birthdate: "",
            firstname: "",
            lastname: "",
            gender: {
              code: "",
              name: ""
            },
            title: "",
            isced: {
              code: "",
              name: ""
            },
            nationality: {
              code: "",
              name: ""
            },
            address: {
              street: "",
              postcode: "",
              city: "",
              country: {
                code: "",
                name: ""
              },
              state: ""
            },
            email: "",
            phoneNumber: "",
            currentJob: ""
          },
          work: {
            phase: []
          },
          education: {
            phase: []
          },
          publications: {
            publication: []
          },
          pdfSettings:{
            pagebreak: {
              interestingFacts: false,
              accessibility: false,
              conditions: false,
              workingHistory: false,
              educationHistory: false,
              otherSkills: false,
              competences: false,
              interests: false,
              languages: false,
            },
            showLanguagesInPdf: false,
            showCompetencesInPdf: false,
            showInterestsInPdf: false,
            showOtherSkillsInPdf: false,
          },
          additionalInformation: {
            language: [],
            competences: "",
            interests: "",
            additionals: "",
            undatedSkill: [],
            undatedOperationArea: [],
            undatedIndustry: []
          },
          photo:{
            class: "",
            filetype: "",
            binary: "",
            id: "",
            size: ""
          },
          additionalImages:[],
          freetexts:{
            mobility:'',
            interestingFacts:'',
            accessibility:'',
            conditions:'',
          }
        }
      },
    };

    const userTab = ref(null)
    const tabs = ref([
      { icon: mdiHomeOutline, title: 'Übersicht', id:'overview' },
      { icon: mdiBriefcaseOutline, title: 'Werdegang',id:'workingHistory' },
      { icon: mdiSchoolOutline, title: 'Ausbildung',id:'educationHistory' },
      { icon: mdiBookmarkOutline, title: 'Allgemein',id:'additionalInformations' },
      /*{ icon: mdiChartLine, title: 'Diagramme' },*/
      /*{ icon: mdiFilePdfBox, title: 'PDF-Layout' },*/
    ])

    const jobDefinitions = ref([]);

    onMounted(()=>{
      if(form.value.aktion == 'editCandidateCheck'){

        axios.post(form.value.url, {aktion:'editCandidate',inputData: form.value.inputData})
          .then((res) => {
            if(res.data.valid === true){
              form.value.inputData = res.data.candidate;
              form.value.stateData = res.data.candidate.stateData;
              jobDefinitions.value = res.data.jobDefinitions;

              if(form.value.inputData.idThJobDefinition > 0){
                tabs.value.push({ icon: mdiChatQuestionOutline, title: 'Interview' ,id:'interview'})
              }

              reloadTable.value = true;
            }
            else{
              res.data.errors.forEach(item=>{
                let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
                vm.$toastr.e(item.errorText, item.errorTitle+suffix);
              })
            }
          })
          .catch((error) => {
            console.log(error);
          })
      }
    })

    const saveMainData = () => {
      const fd = new FormData();
      if(form.value.photo !== null){
        fd.append('photo',form.value.photo,form.value.photo.name);
      }
      fd.append('inputData[reichweiteInKm]',form.value.inputData.reichweiteInKm);
      fd.append('inputData[vorname]',form.value.inputData.vorname);
      fd.append('inputData[name]',form.value.inputData.name);
      fd.append('inputData[status]',form.value.inputData.status);
      fd.append('inputData[strukturierteDatenJson]',JSON.stringify(form.value.inputData.strukturierteDatenJson));
      fd.append('inputData[interview]',JSON.stringify(form.value.inputData.interview));
      fd.append('inputData[idThJobDefinition]',form.value.inputData.idThJobDefinition);
      fd.append('inputData[idThBewerber]',form.value.inputData.idThBewerber);

      if(form.value.inputData.selectedFiles != undefined){
        form.value.inputData.selectedFiles.forEach((item,key) => {
          fd.append('inputData[selectedFiles]['+key+']',item,item.name);
        })
      }
      fd.append('inputData[deletedFiles]',JSON.stringify(form.value.inputData.deletedFiles));
      fd.append('inputData[changedFiles]',JSON.stringify(form.value.inputData.changedFiles));
      fd.append('aktion',form.value.aktion);



      axios.post(form.value.url, fd)
        .then((res) => {
          if(res.data.valid === true){

            form.value.inputData.interview = res.data.interview;
            form.value.inputData.files = res.data.files;
            form.value.inputData.filesResponses = res.data.filesResponses;

            if(res.data.photo.filetype !== undefined){
              form.value.inputData.strukturierteDatenJson.photo = res.data.photo;
            }

            if(res.data.filesResponses.length > 0){
              res.data.filesResponses.forEach((item) => {
                if(item.type == "success"){
                  vm.$toastr.s(item.text,item.dateiname);
                }
                else if(item.type == "error"){
                  vm.$toastr.e(item.text,item.dateiname);
                }
              })
            }
            else{
              vm.$toastr.s('Erfolgreich', 'Daten gespeichert!');
            }

            setupInterviewTab();
          }
          else if(res.data.errors !== undefined){
            res.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
        })
        .catch((error) => {
          console.log(error);
        })
    }

    const setupInterviewTab = () => {
      if(form.value.inputData.idThJobDefinition > 0){
        addInterviewToTabs();
      }
      else{
        removeInterviewsFromTabs();
      }
    }

    const addInterviewToTabs = () =>{
      removeInterviewsFromTabs();
      tabs.value.push({ icon: mdiChatQuestionOutline, title: 'Interview' ,id:'interview'})
    }
    const removeInterviewsFromTabs = () =>{
      tabs.value = tabs.value.filter (x => {
        console.log(x.id)
        return x.id != "interview"
      })
    }

    const saveStateData = () => {
      axios.post(form.value.url, {
        aktion: 'editCandidateStateDataCheck',
        idThBewerber: router.currentRoute.params.idThBewerber,
        stateData: form.value.stateData
      })
        .then((res) => {
          if(res.data.valid === true){
            vm.$toastr.s('Erfolgreich', 'Daten gespeichert!');
          }
          else if(res.data.errors !== undefined){
            res.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
        })
        .catch((error) => {
          console.log(error);
        })
    }

    const editPersonalInformationCheck = (personalInformation) => {
      form.value.inputData.strukturierteDatenJson.personalInformation = personalInformation;
      saveMainData();
    }

    const editJobDefinitionCheck = (idThJobDefinition) => {
      form.value.inputData.idThJobDefinition = idThJobDefinition;
      saveMainData();
    }

    const editReichweiteInKmCopyCheck = (reichweiteInKm) => {
      form.value.inputData.reichweiteInKm = reichweiteInKm;
      saveMainData();
    }

    const editCityAndRangeCheck = (ort,reichweiteInKm) => {
      form.value.inputData.strukturierteDatenJson.personalInformation.address.city = ort;
      form.value.inputData.reichweiteInKm = reichweiteInKm;
      saveMainData();
    }

    const editFreetextsCheck = (item) => {
      form.value.inputData.strukturierteDatenJson.freetexts = item;
      saveMainData();
    }

    const editPhotoCheck = (item) => {
      form.value.photo = item;
      saveMainData();
    }

    const createWorkingHistoryCheck = (newItem) => {
      newItem.key = 0;
      form.value.inputData.strukturierteDatenJson.work.phase.unshift(newItem);
      form.value.inputData.strukturierteDatenJson.work.phase = form.value.inputData.strukturierteDatenJson.work.phase.map((item,key) => {
        item.key = key;
        return item;
      })
      reloadTable.value = true;
      saveMainData();
    }

    const editWorkingHistoryCheck = (key,item) => {
      form.value.inputData.strukturierteDatenJson.work.phase[key] = item;
      reloadTable.value = true;
      saveMainData();
    }

    const sortWorkingHistoryCheck = (items) => {
      form.value.inputData.strukturierteDatenJson.work.phase = overwriteItemKeysByIndex(items);
      reloadTable.value = true;
      saveMainData();
    }

    const deleteWorkingHistoryCheck = (key) => {
      if(typeof(key) == "number"){
        form.value.inputData.strukturierteDatenJson.work.phase = form.value.inputData.strukturierteDatenJson.work.phase.filter (x => x.key != key)
      }
      else{
        form.value.inputData.strukturierteDatenJson.work.phase = form.value.inputData.strukturierteDatenJson.work.phase.filter (x => !key.includes(x.key))
      }
      reloadTable.value = true;
      saveMainData();
    }

    const moveWorkingHistoryCheck = (key) => {
      if(typeof(key) == "number"){
        let phase = form.value.inputData.strukturierteDatenJson.work.phase[key]
        phase = JSON.parse(JSON.stringify(phase))
        phase.key = 0;
        phase.schoolname = phase.company;
        phase.educationType = {code: "", name: ""};
        phase.isced = {code: "", name: ""};
        form.value.inputData.strukturierteDatenJson.education.phase.unshift(phase);
        form.value.inputData.strukturierteDatenJson.education.phase = overwriteItemKeysByIndex(form.value.inputData.strukturierteDatenJson.education.phase);
        form.value.inputData.strukturierteDatenJson.work.phase = form.value.inputData.strukturierteDatenJson.work.phase.filter (x => x.key != key)
      }
      else{
        let phases = form.value.inputData.strukturierteDatenJson.work.phase.filter (x => key.includes(x.key))
        phases = JSON.parse(JSON.stringify(phases))
        phases.forEach((phase) => {
          phase.key = 0;
          phase.schoolname = phase.company;
          phase.educationType = {code: "", name: ""};
          phase.isced = {code: "", name: ""};
          form.value.inputData.strukturierteDatenJson.education.phase.unshift(phase);
        })
        form.value.inputData.strukturierteDatenJson.education.phase = overwriteItemKeysByIndex(form.value.inputData.strukturierteDatenJson.education.phase);
        form.value.inputData.strukturierteDatenJson.work.phase = form.value.inputData.strukturierteDatenJson.work.phase.filter (x => !key.includes(x.key))
      }
      reloadTable.value = true;
      saveMainData();
    }

    const createEducationHistoryCheck = (newItem) => {
      newItem.key = 0;
      form.value.inputData.strukturierteDatenJson.education.phase.unshift(newItem);
      form.value.inputData.strukturierteDatenJson.education.phase = form.value.inputData.strukturierteDatenJson.education.phase.map((item,key) => {
        item.key = key;
        return item;
      })
      reloadTable.value = true;
      saveMainData();
    }

    const editEducationHistoryCheck = (key,item) => {
      form.value.inputData.strukturierteDatenJson.education.phase[key] = item;
      reloadTable.value = true;
      saveMainData();
    }

    const sortEducationHistoryCheck = (items) => {
      form.value.inputData.strukturierteDatenJson.education.phase = overwriteItemKeysByIndex(items);
      reloadTable.value = true;
      saveMainData();
    }

    const deleteEducationHistoryCheck = (key) => {
      if(typeof(key) == "number"){
        form.value.inputData.strukturierteDatenJson.education.phase = form.value.inputData.strukturierteDatenJson.education.phase.filter (x => x.key != key)
      }
      else{
        form.value.inputData.strukturierteDatenJson.education.phase = form.value.inputData.strukturierteDatenJson.education.phase.filter (x => !key.includes(x.key))
      }
      reloadTable.value = true;
      saveMainData();
    }

    const moveEducationHistoryCheck = (key) => {
      if(typeof(key) == "number"){
        let phase = form.value.inputData.strukturierteDatenJson.education.phase[key]
        phase = JSON.parse(JSON.stringify(phase))
        phase.key = 0;
        phase.company = phase.schoolname;
        phase.position = {
          code:phase.comment,
          name:phase.name
        };
        form.value.inputData.strukturierteDatenJson.work.phase.unshift(phase);
        form.value.inputData.strukturierteDatenJson.work.phase = overwriteItemKeysByIndex(form.value.inputData.strukturierteDatenJson.work.phase);
        form.value.inputData.strukturierteDatenJson.education.phase = form.value.inputData.strukturierteDatenJson.education.phase.filter (x => x.key != key)
      }
      else{
        let phases = form.value.inputData.strukturierteDatenJson.education.phase.filter (x => key.includes(x.key))
        phases = JSON.parse(JSON.stringify(phases))
        phases.forEach((phase) => {
          phase.key = 0;
          phase.company = phase.schoolname;
          phase.position = {
            code:phase.comment,
            name:phase.name
          };
          form.value.inputData.strukturierteDatenJson.work.phase.unshift(phase);
        })
        form.value.inputData.strukturierteDatenJson.work.phase = overwriteItemKeysByIndex(form.value.inputData.strukturierteDatenJson.work.phase);
        form.value.inputData.strukturierteDatenJson.education.phase = form.value.inputData.strukturierteDatenJson.education.phase.filter (x => !key.includes(x.key))
      }
      reloadTable.value = true;
      saveMainData();
    }

    const editPdfSettingsCheck = (newItem) => {
      form.value.inputData.strukturierteDatenJson.pdfSettings = newItem
      saveMainData();
    }

    const editInterviewCheck = (newItem) => {
      form.value.inputData.interview = newItem
      saveMainData();
    }

    const editAdditionalInformationCheck = (newItem) => {
      form.value.inputData.strukturierteDatenJson.additionalInformation = newItem
      saveMainData();
    }

    const overwriteItemKeysByIndex = (items) => {
      let newSortedItems = [];
      items.forEach((item,key) => {
        item.key = key;
        newSortedItems[key] = item;
      })
      return newSortedItems;
    }

    const editState = (state) => {
      form.value.inputData.status = state;
      saveMainData();
    }

    const editStateData = (stateData) => {
      form.value.stateData = stateData;
      saveStateData();
    }

    const editHeadhunter = (headhunter) => {
      form.value.inputData.strukturierteDatenJson.headhunter = headhunter;
      saveMainData();
    }

    const editFilesCheck = (selectedFiles,deletedFiles,changedFiles) => {
      form.value.inputData.selectedFiles = selectedFiles;
      form.value.inputData.deletedFiles = deletedFiles;
      form.value.inputData.changedFiles = changedFiles;
      saveMainData();
    }

    return {
      reloadTable,
      editPersonalInformationCheck,
      editJobDefinitionCheck,
      editCityAndRangeCheck,
      editFreetextsCheck,
      editPhotoCheck,
      createWorkingHistoryCheck,
      editWorkingHistoryCheck,
      sortWorkingHistoryCheck,
      deleteWorkingHistoryCheck,
      moveWorkingHistoryCheck,
      createEducationHistoryCheck,
      sortEducationHistoryCheck,
      editEducationHistoryCheck,
      deleteEducationHistoryCheck,
      moveEducationHistoryCheck,
      editPdfSettingsCheck,
      editInterviewCheck,
      editAdditionalInformationCheck,
      editState,
      editStateData,
      editHeadhunter,
      editFilesCheck,
      form,
      jobDefinitions,
      tabs,
      userTab,
      setupInterviewTab,
      addInterviewToTabs,
      removeInterviewsFromTabs
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
