<template>
  <v-dialog
    v-model="isDialogOpen"
    max-width="650px"
    @click:outside="onReset"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        <template v-if="title">
          {{title}}
        </template>
        <template v-else>
          Freitexte bearbeiten
        </template>
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Änderungen können nicht mehr rückgängig gemacht werden.
      </v-card-text>
      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">

            <v-row v-for="(listItem,listItemIndex) in listCopy" :key="listItemIndex" >
              <v-col
                cols="12"
                sm="12"
              >
                <v-textarea
                  v-model="listItem[modelKey]"
                  outlined
                  :label="label"
                  rows="5"
                  hide-details="auto"
                  :placeholder="label"
                ></v-textarea>
              </v-col>

              <v-col
                cols="12"
                sm="1"
              >
              <v-btn
                color="second"
                class=""
                @click="onDeleteClick(listItemIndex)"
              >

                <v-icon
                  size="18"
                  class="me-1"
                >
                  {{ icons.mdiMinus }}
                </v-icon>
                </v-btn>
              </v-col>
            </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="1"
            >
            <v-btn
              color="second"
              class=""
              @click="onCreateClick"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"

                type="submit"
                @click.prevent="onSubmit"
              >
                Speichern
              </v-btn>
              <v-btn
                outlined
                color="secondary"
                @click.prevent="onReset"
              >
                Abbrechen
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import {getCurrentInstance, ref, watch} from "@vue/composition-api";
import {
  mdiPlus,
  mdiMinus,
} from '@mdi/js'

export default {
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default:"Freitexte",
    },
    label: {
      type: String,
      default:"Freitext",
    },
    modelKey: {
      type: String,
      default:"text",
    },
    list: {
      type: Array,
      default() {
        return []
      },
      required: true,
    }
  },
  setup(props, { emit }) {

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    const listCopy = ref([]);

    watch(() => props.list, (newValue,oldValue) => {
      if(props.isDialogOpen){
        listCopy.value = Object.values(newValue);
      }
    });

    const onDeleteClick = (listItemIndex) => {
      listCopy.value = listCopy.value.filter((item,key) => key != listItemIndex);
    }

    const onCreateClick = () => {
      listCopy.value.push({
        text: "",
      })
    }

    const onReset = () => {
      emit('update:is-dialog-open',false)
      listCopy.value = Object.values(props.list);
    }

    const onSubmit = () => {
      emit('update:is-dialog-open',false)
      emit('save',listCopy.value)
    }

    return {
      listCopy,
      onCreateClick,
      onDeleteClick,
      onSubmit,
      onReset,
      icons: {
        mdiPlus,
        mdiMinus
      },
    }
  },
}
</script>

<style>
.v-textarea textarea {
  line-height: 1.2rem;
}
</style>
